import React from 'react';

import { Container } from 'constate';

import { billingEffects } from '../app/effects/billing';

import { FIXED_PRICE_PLAN, ON_DEMAND_PLAN } from '../app/api/constants';

import { Button } from './widgets/button';
import { BlockChoiceRow } from './widgets/grid';

const PlanOutline = ({title, children, onSelect}) =>
  <div className="row center-xs center-sm center-md center-lg">

    <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 ba br2">
      <div className="mb3">

        <div className="mt3">
          <h1>{title}</h1>
        </div>

        <div className="row center-xs center-sm center-md center-lg">
          <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
            {children.map((child, idx) => (
              <div key={idx} className="tl mt2 fw5 lh-copy">
                {child}
              </div>
            ))}
          </div>
        </div>

        <div className="mt5 mb5">
          <Button label={'Activate'} onClick={onSelect} />
        </div>

      </div>
    </div>

  </div>

export const PlanChoice = ({afterSelectPlan}) =>
  <Container
    context='primary'
    effects={billingEffects}>
    {({ selectPlan }) => (
      <div>
        <BlockChoiceRow>

          <PlanOutline choice title='Fixed Price' onSelect={() => doSelect(selectPlan, afterSelectPlan, FIXED_PRICE_PLAN)}>
            {/* <div>£19/month per part time participant</div> */}
            <div>£29/month per full time participant</div>
            <div>Switch to PAYG anytime</div>
          </PlanOutline>

          <PlanOutline choice title='PAYG' onSelect={() => doSelect(selectPlan, afterSelectPlan, ON_DEMAND_PLAN)}>
            <div>£2 per active user day</div>
            <div>Pay only when team members are answering calls</div>
            <div>Switch to fixed pricing anytime</div>
          </PlanOutline>

        </BlockChoiceRow>
      </div>
    )}
  </Container>

  const doSelect = (selectPlan, afterSelectPlan, plan) => {
    selectPlan(plan);
    afterSelectPlan(plan);
  }
