import React, {Component} from 'react';

import { Link } from 'gatsby';
import { Container } from 'constate';

import moment from 'moment';

import { RedirectButton } from '../widgets/button';

import { Button } from '../widgets/button';

import { mandateEffects } from '../../app/effects/mandate';
import { ProfileStatus } from './profile';

import { DetailRow } from '../../components/widgets/grid';

class PaymentMethod extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mandateRequested: false,
    };
  }

  proceed = () => {
    const { requestMandate } = this.props;
    requestMandate();
    this.setState({mandateRequested: true});
  }

  reactivate = () => {
    this.setState({mandateRequested: false});
    this.proceed();
  }

  render() {

    const { mandateRequested } = this.state;
    const { mandateRequestSuccess, redirectUrl, account, segmentTitle} = this.props;

    const { billing : { state, start_date, end_date } } = account;
    const paymentMethodExists = state === 'DD';
    const isTrial = state === 'TRIAL';

    const setupSuccess = mandateRequested && mandateRequestSuccess;
    const shouldRedirect = redirectUrl && mandateRequested && !mandateRequestSuccess;

    const showHint = !setupSuccess && !shouldRedirect;

    const reactivationTriggered = paymentMethodExists && mandateRequested && end_date;

    if (paymentMethodExists && !reactivationTriggered) {
      return (
        <Paid segmentTitle={segmentTitle} startDate={start_date} endDate={end_date} reactivate={this.reactivate}/>
      );
    }
    else if (isTrial && !mandateRequested) {
      return (
        <DetailRow>
          <div segment='true'>{segmentTitle}</div>
          <div prose='true'>Still in the trial period</div>
          <Button action label='Setup payment' onClick={this.proceed} />
        </DetailRow>
      );
    }
    else if (showHint) {
      return (
        <DetailRow>
          <div segment='true'>{segmentTitle}</div>
          <div prose='true'>Add a payment method to complete the change of plan</div>
          <Button action label='Setup payment' onClick={this.proceed} />
        </DetailRow>
      );
    } else if (shouldRedirect) {
      return (
        <DetailRow>
          <div segment='true'>{segmentTitle}</div>
          <div prose='true'>SwitchSmith will collect a prepayment for the first billing cycle - this prepayment will be credited against the first invoice.</div>
          <div rose='true' className='mt2'>Please refer to the <Link className='link blue' to={'/billing'}>billing description</Link> for more detail.</div>
          <RedirectButton action label={'Proceed'} url={redirectUrl} />
        </DetailRow>
      );
    } else if (setupSuccess) {
      return (
        <DetailRow>
          <div segment='true'>{segmentTitle}</div>
          <div prose='true'>
            Mandate was successfully setup.
          </div>
        </DetailRow>
      );
    }
  }
}

const Paid = ({startDate, endDate, reactivate, segmentTitle}) => {
  const startInFuture = moment(startDate).isAfter();
  return (
    <DetailRow>
      <div segment='true'>{segmentTitle}</div>
      <div prose='true'>
        <div className="mb2">Billing via Direct Debit</div>
          { startInFuture && <div>Billing period starts on {startDate}</div> }
          { !startInFuture && <div>Billing started on {startDate}</div> }
      </div>
      { endDate &&
        <Button action label='Reactivate' onClick={reactivate} />
      }
    </DetailRow>
  )
}

const PaymentStatus = ({ data : { profile }, segmentTitle }) =>
  <Container
    context='primary'
    effects={mandateEffects}
    >
    {(props) => (
      <PaymentMethod {...props} segmentTitle={segmentTitle} account={profile.accounts[0]}/>
    )}
  </Container>

export const PaymentState = (props) =>
  <ProfileStatus>
    <PaymentStatus {...props} />
  </ProfileStatus>