import React, { Component } from 'react';

import { navigate } from '@reach/router';
import moment from 'moment';

import { Container } from 'constate';

import { billingEffects } from '../../app/effects/billing';

import Layout from '../../components/layout';

import { PageTitle } from '../../components/title';
import { PlanChoice } from '../../components/plan';
import { GridRow, DetailRow } from '../../components/widgets/grid';
import { Button } from '../../components/widgets/button';

import { ProfileStatus } from '../../components/state/profile';

import { PaymentState } from '../../components/state/payment';

import { planLabel, accountTypeLabel } from '../../app/nomenclature/billing';

class BillingState extends Component {

  constructor(props) {
    super(props);

    this.state = {
      planChoiceTriggered: false,
    };
  }

  triggerPlanChoice = () => this.setState({ planChoiceTriggered: true })
  clearPlanChoice = () => this.setState({ planChoiceTriggered: false })

  render() {

    const { planChoiceTriggered } = this.state;

    const { account } = this.props;
    const { billing } = account;

    if (planChoiceTriggered) {
      return (
        <div className="mb4">
          <GridRow>
            <h2>Choose Your Plan</h2>
          </GridRow>
          <PlanChoice afterSelectPlan={this.clearPlanChoice}/>
        </div>
      )
    }
    else {
      return (
        <div className="mt3 mb4">
            <BillingDetails
              billing={billing}
              triggerPlanSelection={this.triggerPlanChoice}
              clearPlanChoice={this.clearPlanChoice} />
        </div>
      );
    }
  }

}

const upgradeLabel = (state) => {
  switch (state) {
    case 'TRIAL':
    case 'FREE': {
      return 'Upgrade Plan';
    }
    case 'EXPIRED': {
      return 'Add Payment Method';
    }
  }
}

const BillingDetails = ({billing: { id, plan, state, start_date, end_date}, triggerPlanSelection, clearPlanChoice}) => {

  const billingExpired = end_date && moment(end_date).isBefore(moment(), "day");

  // Backend seems to be sending through expired state with state = 'DD'

  const canStartTrial = state === 'FREE';
  const isTrial = state === 'TRIAL'

  const paymentMethod = state === 'DD';
  const tentativePlan = plan != undefined;
  const upgradePlan = !paymentMethod && !tentativePlan;
  const changePlan = tentativePlan || paymentMethod;

  const currentPlan = (plan && planLabel(plan)) || accountTypeLabel(state, plan);
  const label = upgradeLabel(state);

  const isCancelled = end_date != undefined;

  return (
    <div className="mb3">

      <DetailRow>
        <div segment='true'>Plan</div>
        <div prose='true' className="">{ currentPlan }</div>
        { canStartTrial &&

          <Container
            action
            context='primary'
            effects={billingEffects}>
            {({commenceTrial}) => (
              <Button action label={'Start Trial'} onClick={commenceTrial} />
            )}
          </Container>
        }
        { !canStartTrial && upgradePlan && <Button action label={label} onClick={triggerPlanSelection} /> }
        { !canStartTrial && changePlan && !isCancelled && <Button action label={'Change Plan'} onClick={triggerPlanSelection} /> }
      </DetailRow>

      { !upgradePlan &&
        <PaymentState segmentTitle='Payment' prose/>
      }

      { upgradePlan &&
        <DetailRow>
          <div segment='true'>Payment</div>
          <div prose='true'>No payment method on file</div>
        </DetailRow>
      }

      { paymentMethod &&
        <DetailRow>
          <div segment='true'>Downgrade</div>
          { isCancelled &&
            <div prose='true'>
              <div>Plan due to downgrade on {end_date}</div>
              <div className='mt2'>Set up a new direct debit to reactivate services</div>
            </div>
          }
          { !isCancelled && <div prose='true'>Downgrade anytime, but you'll lose access to telephony services</div> }
          { !isCancelled && <Button action label='Downgrade Plan' onClick={() => navigate('/app/cancel')} /> }
        </DetailRow>
      }
    </div>
  );
}

const BillingStatus = ({ data : { profile } }) => (
  <div>
    <PageTitle title="Billing"/>
    <BillingState account={profile.accounts[0]}/>
  </div>
);

const BillingPage = () => (
  <Layout>
    <ProfileStatus>
      <BillingStatus/>
    </ProfileStatus>
  </Layout>
)

export default BillingPage;